import './styles/globals.css'
import {MantineProvider} from "@mantine/core";
import {NotificationsProvider} from "@mantine/notifications";
import {theme} from "./styles/theme";
import {Route, Routes} from "react-router-dom";
import BaseLayout from "./components/layout/BaseLayout";
import OrderResponsePage from "./pages/order_details";
import CheckTransactionPage from "./pages/txn";
import CreatePaymentPage from "./pages/create_payment";
import Error404 from "./components/error404";

function App() {
  return (
      <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={theme}
      >
        <NotificationsProvider position="top-center">
            <BaseLayout>
                <Routes>
                    <Route path="/o/:orderId/:token/pay" element={<CreatePaymentPage />} />
                    <Route path="/o/:orderId/:token/txn/:txnId" element={<CheckTransactionPage />} />
                    <Route path="/o/:orderId/:token" element={<OrderResponsePage />} />

                    <Route path="*" element={<Error404 />} />
                </Routes>
            </BaseLayout>
        </NotificationsProvider>
      </MantineProvider>
  );
}

export default App;
