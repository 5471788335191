import {Stack, Loader, Text} from "@mantine/core";
import useSWR from "swr";
import api from "../lib/api";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import CyberSourceForm from "../components/order_details/payments/cybersource";
import {useEffect, useState} from "react";
import ErrorScreen from "../components/order_details/error";

export default function CreatePaymentPage() {
    const { t } = useTranslation();

    // Extract parameters from route
    const { orderId, token, txnId } = useParams();

    // Get payment data
    const [status, setStatus] = useState(true);
    const [resData, setResData] = useState({
        form_params: {},
        target_url: null,
        transaction_id: null
    });

    const [error, setError] = useState(null)

    useEffect(() => {
        setStatus(true)
        api.get(`/api/order/${orderId}/${token}`)
            .then(() => {
                api.post(`/api/order/${orderId}/${token}/qrcode/pay`).then((res) => {
                    setResData(res.data)
                    setStatus(false)
                }).catch((err) => {
                    setError(err)
                })
            })
    }, []);

    if (error)
        return <ErrorScreen error={error.response.status === 404 ? 'error_messages.order_not_found' : (error?.response?.data?.message?.toString() ?? error.toString())} />;

    return (
        <div>
            <Stack align="center" py={32}>
                <Loader size={64} />
                <Text size="sm">{t('transaction_pay_message')}</Text>
            </Stack>
            {
                !status && (
                <div style={{opacity: 0}}>
                    <CyberSourceForm params={resData.form_params} targetUrl={resData.target_url}/>
                </div>)
            }
        </div>

    );

}
